<template>
  <section>
    <th-wrapper :title="$t('common.titles.general_info.title')">
      <!-- Transit Account -->
      <div>
        <th-input-title
          :title="$t('pages.settings.accounting.transit_account')"
          title-for="transit_account"
        />

        <el-input
          id="transit_account"
          v-model="transitAccount"
          clearable
          type="text"
          class="max-w-xs"
        />
      </div>
    </th-wrapper>

    <datev v-if="isDatevEnabled" ref="datev" />
  </section>
</template>

<script>
import Datev from './datev'
import safeGet from 'just-safe-get'

export default {
  name: 'Accounting',
  components: {
    Datev
  },
  data() {
    return {
      isSaving: false,
      payload: {}
    }
  },
  computed: {
    transitAccount: {
      get() {
        return safeGet(
          this.$store.state.Config.clientAccountConfiguration,
          'financials.fa_transit_account'
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'financials.fa_transit_account',
          value: newValue || null
        })
      }
    },
    isDatevEnabled() {
      return this.$isFeatureEnabled('datev')
    }
  },
  created() {
    this.$emitter.on('save-start', () => {
      this.isSaving = true
    })
    this.$emitter.on('save-done', () => {
      this.isSaving = false
    })
  },
  mounted() {
    this.payload = {
      transitAccount: this.transitAccount
    }
  },
  methods: {
    validate(cb) {
      if (!this.isDatevEnabled) return cb(true)
      this.$refs.datev.validate(async (valid) => {
        // we want to warn user about DATEV related changes only on saving and not on leaving the page, because then it will be redundant
        const shouldWarnOnDatevChange =
          this.isSaving &&
          this.isDatevStrictMode === false &&
          this.payload.transitAccount !== this.transitAccount

        if (valid && shouldWarnOnDatevChange) {
          const objectName = this.$t(
            'pages.settings.accounting.transit_account'
          )
          try {
            await this.$confirm(
              this.$t('common.datev.related_change.warning.message', {
                objectName
              }),
              this.$t('common.titles.warning'),
              {
                confirmButtonText: this.$t('common.interactions.buttons.ok'),
                cancelButtonText: this.$t('common.interactions.buttons.cancel'),
                type: 'warning'
              }
            )

            this.payload.transitAccount = this.transitAccount
          } catch (err) {
            return cb(false)
          }
        }

        return cb(valid)
      })
    }
  }
}
</script>
