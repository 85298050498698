<template>
  <th-wrapper :title="$t('pages.settings.accounting.datev.title')">
    <el-form ref="form" :model="form" :rules="rules" class="max-w-xs">
      <el-form-item prop="strict_mode" class="leading-normal">
        <el-checkbox v-model="strict_mode" :disabled="!isStrictModeFieldValid">
          {{ $t(`pages.settings.accounting.datev.strict_mode.label`) }}
        </el-checkbox>
      </el-form-item>

      <el-form-item
        prop="advisor_number"
        :label="$t(`pages.settings.accounting.datev.advisor_number.label`)"
      >
        <el-input
          id="advisor_number"
          v-model="advisor_number"
          clearable
          type="text"
        />
      </el-form-item>

      <el-form-item
        prop="client_number"
        :label="$t(`pages.settings.accounting.datev.client_number.label`)"
      >
        <el-input
          id="client_number"
          v-model="client_number"
          clearable
          type="text"
        />
      </el-form-item>

      <el-form-item
        class="input-holder"
        prop="business_year"
        :label="$t(`pages.settings.accounting.datev.business_year.label`)"
      >
        <date-picker-input
          id="business_year"
          v-model="business_year"
          class="w-full"
        />
      </el-form-item>

      <el-form-item
        prop="account_number_length"
        :label="
          $t(`pages.settings.accounting.datev.account_number_length.label`)
        "
      >
        <el-select
          id="account_number_length"
          v-model="account_number_length"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="item in ['3', '4', '5', '6', '7', '8', '9']"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        class="mb-0"
        prop="skr"
        :label="$t(`pages.settings.accounting.datev.skr.label`)"
      >
        <el-select id="skr" v-model="skr" :popper-append-to-body="false">
          <el-option
            v-for="item in ['03', '04']"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
    </el-form>
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'
import parseDate from 'date-fns/parse'
import formatDate from 'date-fns/format'
import { mapGetters } from 'vuex'
import { DATEV, datevMap } from './helpers'
import DatePickerInput from '@/components/inputs/date-picker'

export default {
  name: 'AccountingDatev',
  components: {
    DatePickerInput
  },
  data() {
    return {
      rules: {
        advisor_number: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          },
          {
            min: 1,
            max: 7,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 1,
              max: 7
            }),
            trigger: 'change'
          }
        ],
        client_number: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          },
          {
            min: 1,
            max: 5,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 1,
              max: 5
            }),
            trigger: 'change'
          }
        ],
        business_year: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          }
        ],
        account_number_length: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          }
        ],
        skr: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'Auth/isAdmin'
    }),
    datevPayload() {
      return get(this.$store, 'state.Config.payload.datev') || {}
    },
    strict_mode: {
      get() {
        const strictMode = get(
          this.$store.state.Config.clientAccountConfiguration,
          [DATEV, datevMap.STRICT_MODE]
        )
        return typeof strictMode === 'boolean' ? strictMode : true
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: [DATEV, datevMap.STRICT_MODE],
          value: newValue
        })
      }
    },
    advisor_number: {
      get() {
        return (
          get(this.$store.state.Config.clientAccountConfiguration, [
            DATEV,
            datevMap.ADVISOR_NUMBER
          ]) || null
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: [DATEV, datevMap.ADVISOR_NUMBER],
          value: newValue || null
        })
      }
    },
    client_number: {
      get() {
        return (
          get(this.$store.state.Config.clientAccountConfiguration, [
            DATEV,
            datevMap.CLIENT_NUMBER
          ]) || null
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: [DATEV, datevMap.CLIENT_NUMBER],
          value: newValue || null
        })
      }
    },
    business_year: {
      get() {
        const storedDate = get(
          this.$store.state.Config.clientAccountConfiguration,
          [DATEV, datevMap.BUSINESS_YEAR]
        )

        if (!storedDate) return null

        const date = parseDate(storedDate, 'yyyyMMdd', new Date())

        /*
          This is a fix to solve this problem:
          https://stackoverflow.com/questions/69160033/parse-date-fns-returns-one-day-previous-value
          Is there a better solution?
         */
        const timezoneOffset = date.getTimezoneOffset()
        date.setMinutes(timezoneOffset * -1)

        return date.toISOString()
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: [DATEV, datevMap.BUSINESS_YEAR],
          value: newValue ? formatDate(new Date(newValue), 'yyyyMMdd') : null
        })
      }
    },
    account_number_length: {
      get() {
        return (
          get(this.$store.state.Config.clientAccountConfiguration, [
            DATEV,
            datevMap.ACCOUNT_NUMBER_LENGTH
          ]) || null
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: [DATEV, datevMap.ACCOUNT_NUMBER_LENGTH],
          value: newValue || null
        })
      }
    },
    skr: {
      get() {
        return (
          get(this.$store.state.Config.clientAccountConfiguration, [
            DATEV,
            datevMap.SKR
          ]) || null
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: [DATEV, datevMap.SKR],
          value: newValue || null
        })
      }
    },
    form() {
      return {
        advisor_number: this.advisor_number,
        client_number: this.client_number,
        business_year: this.business_year,
        account_number_length: this.account_number_length,
        skr: this.skr
      }
    },
    isStrictModeFieldValid() {
      return (
        this.isAdmin &&
        !!this.advisor_number &&
        !!this.client_number &&
        !!this.business_year
      )
    }
  },
  methods: {
    validate(cb) {
      this.$refs.form.validate(async (valid) => {
        const shouldContinue = await this.shouldWarnOnRiskyChange()
        return shouldContinue && cb(valid)
      })
    },
    async shouldWarnOnRiskyChange() {
      const skrChanged = this.skr !== this.datevPayload[datevMap.SKR]
      const accountNumberLengthChanged =
        this.account_number_length !==
        this.datevPayload[datevMap.ACCOUNT_NUMBER_LENGTH]

      // skr and account_number_length are data that the user should be very careful not to change after it being set up, because of legal reasons.
      // currently we would just warn the user whenever these two fields are changed, but we're not preventing the user from making mistakes, but we do warn them.
      // more context: https://tillhub.atlassian.net/browse/DAS-734
      const shouldWarn = skrChanged || accountNumberLengthChanged

      if (!shouldWarn) return true

      try {
        await this.$confirm(
          this.$t(
            'pages.settings.accounting.datev.dangerous_fields_change.warning.content'
          ),
          this.$t('common.titles.warning'),
          {
            confirmButtonText: this.$t('common.interactions.buttons.ok'),
            cancelButtonText: this.$t('common.interactions.buttons.cancel'),
            type: 'warning'
          }
        )
        return true
      } catch {
        return false
      }
    }
  }
}
</script>
